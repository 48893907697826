import React from 'react';
import {Button, Box, ListItem, ButtonGroup, CircularProgress, makeStyles, Theme, createStyles, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import FarmPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/FarmPicker';
import DatePeriodPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/DatePeriodPicker';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {handleDateFormat} from '../../../helpers/dateHelpers';
import {IDiseaseIndividualResponse} from '@deep-planet/api-interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 64px)',
    },
    sideList: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    selectSection: {
      padding: '1rem',
    },
    updateButton: {
      margin: '1rem 0',
      width: '100%',
    },
    buttonList: {
      overflow: 'auto',
    },
    sideListItemText: {
      fontSize: '0.8125rem',
      marginLeft: 5.5,
    },
    dateButton: {
      width: '100%',
    },
    circularProgress: {
      color: 'white',
    },
  })
);

interface SidebarProps {
  selectedFarm: IFarm;
  allFarms: IFarm[];
  startDate: Date;
  endDate: Date;
  loadingDates: boolean;
  files: IDiseaseIndividualResponse[];
  selectedFiles: IDiseaseIndividualResponse[];
  handleFarmSelection: (farmId: string) => void;
  handleStartDateSelection: (date: Date) => void;
  handleEndDateSelection: (date: Date) => void;
  handleGetFiles: (selectedFarm: IFarm, startDate: Date, endDate: Date) => void;
  handleSelectDate: (date: number) => void;
}

const Sidebar = ({
  selectedFarm,
  allFarms,
  startDate,
  endDate,
  loadingDates,
  files,
  selectedFiles,
  handleFarmSelection,
  handleStartDateSelection,
  handleEndDateSelection,
  handleGetFiles,
  handleSelectDate,
}: SidebarProps) => {
  const {t} = useTranslation();
  const classes = useStyles();

  const uniqueDates = [];

  files?.forEach(file => {
    if (uniqueDates.includes(file?.date)) {
      return;
    } else if (file) {
      uniqueDates.push(file.date);
    }
  });

  const sortedDates = uniqueDates?.sort((a, b) => b - a);

  return (
    <Box className={classes.container}>
      <Box id={'cucc'} className={classes.sideList}>
        <Box className={classes.selectSection}>
          <Box width="100%">
            <FarmPicker activeFarm={selectedFarm?.farmid} allFarms={allFarms} handleChange={handleFarmSelection} />
          </Box>
          <DatePeriodPicker
            disableToolbar={false}
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={handleStartDateSelection}
            handleEndDateChange={handleEndDateSelection}
            disableFuture={true}
          />
          <Button
            className={classes.updateButton}
            variant="contained"
            color="primary"
            onClick={() => {
              handleGetFiles(selectedFarm, startDate, endDate);
            }}
          >
            {loadingDates ? <CircularProgress className={classes.circularProgress} size={23.5} /> : t('ndvi.sidebar.update')}
          </Button>
        </Box>
        {!!sortedDates && (
          <Box className={classes.buttonList}>
            {sortedDates?.map(date => {
              return (
                <ListItem key={date} button style={{backgroundColor: selectedFiles?.some(file => file?.date === date) ? '#DBDBDB' : 'unset'}}>
                  <ButtonGroup variant="text" size="small" aria-label="small outlined button group">
                    <Button
                      variant="contained"
                      color={selectedFiles?.some(file => file?.date === date) ? 'primary' : 'default'}
                      onClick={() => {
                        handleSelectDate(date);
                      }}
                    >
                      {handleDateFormat(date)}
                    </Button>
                  </ButtonGroup>
                </ListItem>
              );
            })}
          </Box>
        )}
        {!sortedDates?.length && (
          <Box pr={2} pl={2}>
            <Typography align="center">{t('ndvi.sidebar.no.images')}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
