// Custom hook to emulate the numpy Python library operations
export const useNumpy = () => {
  // Generates an array of sequential numbers from minimum to maximum
  function arrange(minimum: number, maximum: number) {
    const arrangedArr: number[] = [];
    for (let i = minimum; i < maximum; i++) {
      arrangedArr.push(i);
    }
    return arrangedArr;
  }

  // Evenly finds spaces within an range of numbers
  function linspace(start: number, end: number, num: number) {
    const result = [];
    // Calculate step based off difference and space numbers
    const step = (end - start) / (num - 1);
    for (let i = 0; i < num; i++) {
      // Add result to array
      result.push(start + i * step);
    }
    return result;
  }

  // https://stackoverflow.com/questions/35408593/generate-grid-mesh
  function createMeshGrid(xRange: number[], yRange: number[]) {
    const x: number[][] = [];
    const y: number[][] = [];
    for (let i = 0; i < yRange.length; i++) {
      x.push([]);
      y.push([]);
      for (let j = 0; j < xRange.length; j++) {
        x[i].push(xRange[j]);
        y[i].push(yRange[i]);
      }
    }

    return {x, y};
  }

  return {arrange, linspace, createMeshGrid};
};
