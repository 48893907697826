import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import Plot from 'react-plotly.js';
import {ColorScale} from 'plotly.js';
import {useNumpy} from '../../../hooks/useNumpy';
import {ISoilMoisturePlotData} from '@deep-planet/api-interfaces';

interface SoilMoisturePlotProps {
  plotData: ISoilMoisturePlotData;
  handlePlotInitialised: () => void;
}

const SoilMoisturePlot = ({plotData, handlePlotInitialised}: SoilMoisturePlotProps) => {
  const {t} = useTranslation();
  const {arrange, linspace, createMeshGrid} = useNumpy();

  if (!plotData) return;

  // Camera's original default position
  const xEye = 0;
  const yEye = -2.5;
  const zEye = 1.25;

  // Colorbar parameters
  const colorbarParams = plotData.colorbar_params;
  const cMin = 0;
  const cMax = 255;

  // Colorscale on plot
  const colorScale: ColorScale = [
    [0.0, 'rgb(165, 0, 38)'],
    [0.5, 'rgb(255, 255, 191)'],
    [1.0, 'rgb(0, 104, 55)'],
  ];

  const height = plotData.Z.length;
  const width = plotData.Z[0].length;

  // Creates numerical ranges for x and y axes
  const xRange = arrange(0, width);
  const yRange = arrange(0, height).reverse();

  const {x, y} = createMeshGrid(xRange, yRange); // Generates grid mesh using xRange and yRange

  const z = plotData.Z; // Contains soil moisture data matrix for the surface plot
  const depths = Object.keys(plotData).filter(key => key !== 'unit' && key !== 'colorbar_params'); // Extract depth values from plotData keys, excluding metadata
  const surfaceList: any[] = [];
  const tickValues: number[] = [];
  const tickText: string[] = [];

  let first = true; // Tracks the first depth
  let maxZ = -Infinity; // Holds the highest z-value point on the plot

  for (const depth of depths) {
    const curDepth = parseInt(depth);

    let depthMinZ = Infinity; // Holds the lowest z-value point for each depth
    let depthMaxZ = maxZ; // Holds the highest z-value point for each depth

    // Spaces out each depth value so they appear on top of eachother with spacing
    const curZ = z.map((list: number[]) => {
      return list.map((value: number) => {
        if (value) {
          // The plot point is less than the current depth min (z)
          if (value < depthMinZ) {
            depthMinZ = value - curDepth * 13; // depthMinZ is the value minus the current depth * 13 (for spacing)
          }
          // The plot point is greater than the current depth max (z)
          if (value > depthMaxZ) {
            depthMaxZ = value;
          }
          return value - curDepth * 13; // Offsets Z values by subtracting depth * 13 to create spacing between depth layers
        }
        return value;
      });
    });

    const colorDimension = plotData[depth];

    // Push surface to list
    surfaceList.push({
      type: 'surface',
      z: curZ,
      x: x,
      y: y,
      cmin: cMin,
      cmax: cMax,
      showscale: first ? true : false, // Only show the color scale for the first surface layer to avoid redundancy
      colorscale: colorScale,
      hoverinfo: 'none',
      surfacecolor: colorDimension,
      colorbar: {
        title: plotData.unit,
        tickvals: linspace(cMin, cMax, colorbarParams[2]),
        ticktext: linspace(colorbarParams[0], colorbarParams[1], colorbarParams[2]),
        lenmode: 'fraction',
        len: 0.9,
      },
      contours: {
        z: {
          highlight: false,
        },
        x: {
          highlight: false,
        },
        y: {
          highlight: false,
        },
      },
    });

    first = false;
    maxZ = depthMaxZ;
    tickValues.push(depthMinZ); // Minimum Z value is pushed to tickValues list (positions the curve)
    tickText.push(`${depth}cm`); // Text shown for the tick is the curve's depth
  }

  // Annotation data for the north arrow
  const northArrow: Partial<Plotly.PlotData> = {
    type: 'scatter3d',
    mode: 'text+lines+markers',
    x: [xRange[xRange.length - 1] / 2, xRange[xRange.length - 1] / 2], // Middle of x axis
    y: [surfaceList[0].y.length, surfaceList[0].y.length], // End of y axis
    z: [maxZ - 150, maxZ - 150], // Z axis above surface data
    line: {
      color: 'black',
      width: 0,
    },
    marker: {
      size: [0, 0],
      color: 'black',
    },
    text: ['', 'N'],
    textposition: 'top center',
    textfont: {
      size: 16,
      color: '#1f77b4',
    },
    hoverinfo: 'none',
    showlegend: false,
  };

  return (
    <Plot
      data={[...surfaceList, northArrow]}
      layout={{
        width: window.innerWidth - 500,
        height: window.innerHeight - 165,
        scene: {
          xaxis: {showticklabels: false, title: '', showspikes: false, spikesides: false},
          yaxis: {showticklabels: false, title: '', showspikes: false, spikesides: false},
          zaxis: {
            title: {text: t('soil.moisture.depth.and.topology'), font: {size: 13}},
            tickvals: tickValues,
            ticktext: tickText,
            showspikes: false,
            spikesides: false,
            tickfont: {
              size: 11,
            },
          },
          camera: {eye: {x: xEye, y: yEye, z: zEye}},
          aspectmode: 'data',
        },
        margin: {l: 3, r: 3, b: 3, t: 3},
        uirevision: 'constant',
        paper_bgcolor: 'rgba(0,0,0,0)',
      }}
      config={{modeBarButtonsToRemove: ['pan3d', 'orbitRotation', 'resetCameraDefault3d']}}
      onInitialized={handlePlotInitialised}
    />
  );
};

export default memo(SoilMoisturePlot);
