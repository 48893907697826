import React from 'react';
import {IconButton} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const RightbarButton = ({rightbarOpen, onClick}) => {
  return (
    <div>
      {rightbarOpen ? (
        <IconButton style={{position: 'absolute', top: 8, right: 2}} onClick={onClick}>
          <ChevronRightIcon />
        </IconButton>
      ) : (
        <IconButton style={{position: 'absolute', top: 8, right: 2}} onClick={onClick}>
          <ChevronLeftIcon />
        </IconButton>
      )}
    </div>
  );
};

export default RightbarButton;
