import {IFarmEntity, IFeature, IOrganizationEntity, IPolygonEntity} from '@deep-planet/api-interfaces';
import {useState} from 'react';
import {IFarm} from '../store/reducers/farm';
import axios from 'axios';
import {baseApiUrl} from '../config/const';
import {getOrgUserFarms} from '../utilities/parseFarm';
import {useSelector} from 'react-redux';
import {organizationsSelector} from '../store/selectors';

export const useDownloadMeanData = () => {
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizationEntity>(null);
  const [selectedFarm, setSelectedFarm] = useState<IFarm>(null);
  const [selectedPolygon, setSelectedPolygon] = useState<IPolygonEntity>(null);
  const [isLoadingPolygons, setIsLoadingPolygons] = useState(false);
  const [farms, setFarms] = useState<IFarm[]>();
  const [checked, setChecked] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<IFeature>();
  const organizations = useSelector(organizationsSelector);

  // handle check box
  const handleCheckboxChange = async event => {
    setChecked(event.target.checked);
    if (event.target.checked && !selectedFarm.polygons) getPolygons(selectedFarm);
  };

  const handleOrganizationSelection = (currentlySelectedOrganization: IOrganizationEntity) => {
    if (currentlySelectedOrganization) {
      setSelectedOrganization(currentlySelectedOrganization);
      // get farms that belongs to the organization
      const farms = getOrgUserFarms(organizations, currentlySelectedOrganization.id);
      setFarms(farms);
      // reset previous selected farm to null
      setSelectedFarm(null);
    }
  };

  const handleFarmSelection = async (farm: IFarm) => {
    // get polygons for the selected farm if "block level" check-box is selected
    if (checked) getPolygons(farm);
    else setSelectedFarm(farm);
  };

  const getPolygons = async (farm: IFarm) => {
    // bring polygons data from the backend.
    setIsLoadingPolygons(true);
    try {
      const {data} = await axios.get(`${baseApiUrl}/farm/${farm.id}/polygon-names`);
      if (data) setSelectedFarm({...farm, polygons: data});
    } catch (e) {
      // no error
      setSelectedFarm(farm);
    }
    setIsLoadingPolygons(false);
  };

  return {
    selectedOrganization,
    selectedFarm,
    selectedPolygon,
    selectedFeature,
    isLoadingPolygons,
    farms,
    checked,
    setSelectedOrganization,
    setSelectedPolygon,
    setSelectedFeature,
    setIsLoadingPolygons,
    handleFarmSelection,
    handleOrganizationSelection,
    handleCheckboxChange,
  };
};
