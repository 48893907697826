import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FaMapSigns} from 'react-icons/fa';
import {Box, CircularProgress, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {useSoilMoistureInterpolation} from '../../../hooks/useSoilMoistureInterpolation';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {withUser} from '../../../hooks/useAuth';
import Layout from '../../Layout';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import Sidebar from './Sidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import MapPlaceholder from '../../../components/UI/MapPlaceholder';
import {PageTitle} from 'apps/web-portal-ui/src/app/components/UI/PageTitle';
import {organizationsSelector, selectedOrganizationSelector} from '../../../store/selectors';
import SoilMoisturePlot from './SoilMoisturePlot';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      height: 'calc(100% - 65px)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 0, 0, 0.025)',
    },
    layoutContainer: {overflow: 'hidden', position: 'relative', backgroundColor: 'white'},
    messageContainer: {height: '85%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'},
    message: {marginTop: 10},
    subtitleMessage: {color: 'grey'},
    logo: {width: '100px', height: '100px', marginBottom: 20},
  })
);

const SoilMoistureInterpolation = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const organizations = useSelector(organizationsSelector);
  const {allFarms, activeFarm, selectedFarm, setSelectedFarm, loadingGetFarm: loadingFarms} = useFarmSelection();
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const {plotData, files, selectedFile, loadingDates, loadingGraph, renderingGraph, error, handlePlotInitialised, handleSelectedDateChange, handleSubmit, resetFiles} = useSoilMoistureInterpolation(
    organizations
  );

  const handleFarmChange = (farmId: string) => {
    resetFiles();
    const selectedFarm = allFarms.find(({farmid}) => farmid === farmId);
    setSelectedFarm(selectedFarm);
  };

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const isLoading = loadingFarms;
  const selectedActiveFarm = activeFarm || (allFarms && allFarms[0]);

  useEffect(() => {
    if (selectedOrganization) {
      resetFiles();
    }
  }, [resetFiles, selectedOrganization]);

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && selectedActiveFarm && !selectedActiveFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={selectedActiveFarm.id} />}
      {!isLoading && selectedActiveFarm && (
        <Box id="layout" className={classes.layoutContainer}>
          <ContentLeftSidebar
            sidebar={
              <Sidebar
                selectedOrganization={selectedOrganization}
                organizations={organizations}
                selectedFarm={selectedFarm}
                allFarms={allFarms}
                startDate={startDate}
                endDate={endDate}
                files={files}
                selectedFile={selectedFile}
                loadingDates={loadingDates}
                handleChange={handleFarmChange}
                handleSubmit={handleSubmit}
                handleStartDateSelection={handleStartDateSelection}
                handleEndDateSelection={handleEndDateSelection}
                handleSelectedDateChange={handleSelectedDateChange}
              />
            }
            content={
              <>
                {/* Page title */}
                <PageTitle title={t('soil.moisture.interpolation.title')} />
                {/* Loading */}
                {loadingGraph && (
                  <Box className={classes.mainContainer}>
                    <Box className={classes.messageContainer}>
                      <CircularProgress size={100} />
                      <Typography className={classes.message} variant="h6">
                        {renderingGraph ? t('soil.moisture.rendering.plot') : t('soil.moisture.downloading.plot')}
                      </Typography>
                      <Typography className={classes.subtitleMessage}>{t('soil.moisture.interpolation.loading.subtitle')}</Typography>
                    </Box>
                  </Box>
                )}
                {/* Error */}
                {error && (
                  <Box className={classes.mainContainer}>
                    <Box className={classes.messageContainer}>
                      <FaMapSigns className={classes.logo} />
                      <Typography variant="h6">{t('soil.moisture.interpolation.graph.error')}</Typography>
                      <Typography className={classes.subtitleMessage}>{t('soil.moisture.interpolation.graph.error.subtitle')}</Typography>
                    </Box>
                  </Box>
                )}
                {/* Plot */}
                {plotData && (
                  <Box className={classes.mainContainer}>
                    <SoilMoisturePlot plotData={plotData} handlePlotInitialised={handlePlotInitialised} />
                  </Box>
                )}
                {/* No data */}
                {!loadingGraph && !error && !plotData && (
                  <Box className={classes.mainContainer}>
                    <Box className={classes.messageContainer}>
                      <CalendarTodayIcon className={classes.logo} />
                      <Typography variant="h6">{t('soil.moisture.interpolation.no.imagery.selected')}</Typography>
                      <Typography className={classes.subtitleMessage}>{t('soil.moisture.interpolation.no.imagery.selected.subtitle')}</Typography>
                    </Box>
                  </Box>
                )}
              </>
            }
          />
        </Box>
      )}
    </Layout>
  );
};

export default withUser(SoilMoistureInterpolation);
