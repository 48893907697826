export const baseApiUrl = process.env.REACT_APP_API_URL || process.env.NX_REACT_APP_API_URL;
export const baseWeatherUrl = process.env.REACT_APP_WEATHER_API_URL || process.env.NX_REACT_APP_WEATHER_API_URL;
export const baseSoilNutrientUrl = process.env.NX_SOIL_NUTRIENT_API_URL;
export const baseMaturityCurveUrl = process.env.NX_MATURITY_CURVE_LAMBDA_URL;
export const baseMaturityMapUrl = process.env.NX_MATURITY_MAP_LAMBDA_URL;
export const baseDiseaseMapUrl = process.env.NX_DISEASE_MAP_LAMBDA_URL;
export const baseYieldApiUrl = process.env.NX_YIELD_API_URL;
export const imageGenerationURL = process.env.NX_IMAGE_GENERATION_URL;

export const CURRENT = 'current';
export const VINE_HEALTH = 'VINE_HEALTH';
export const SOIL_HEALTH = 'SOIL_HEALTH';
export const WATER = 'WATER';
export const HARVEST = 'HARVEST';
export const BIOMASS = 'BIOMASS';
export const PLANT = 'PLANT';
export const vineHealth = 'Vine Health';
export const soilHealth = 'Soil Health';
export const water = 'Water';
export const harvest = 'Harvest';
export const biomass = 'Biomass';
export const plant = 'Plant';
export const vineSignal = 'vine-signal';
export const soilSignal = 'soil-signal';
export const cropSignal = 'crop-signal';
export const Config = 'Config';
export const PERPIXELYIELD = 'PERPIXELYIELD';
export const PLANET = 'PLANET';
export const YIELD = 'YIELD';
export const SOILNUTRIENT = 'SOILNUTRIENT';
export const MATURITYMAP = 'MATURITYMAP';
export const ESA = 'ESA';
export const SOILORGANICCARBON = 'SOILORGANICCARBON';
export const MATURITYCURVE = 'MATURITYCURVE';
export const IRRIGATION = 'IRRIGATION';
export const CONFIG = 'CONFIG';
export const DISEASE = 'DISEASE';
export const DISEASEMAP = 'DISEASEMAP';
export const disease = 'disease';
export const SEASONINDICES = 'SEASONINDICES';
// remove imagery paths
export const ESA_DELETE_URL_PATH = 'esa/delete';
export const MATURITY_MAP_DELETE_URL_PATH = 'maturity_image/delete';
export const SOILNUTRIENT_DELETE_URL_PATH = 'soil-nutrient/delete';
export const SOILCARBON_DELETE_URL_PATH = '';
export const MATURITYCURVE_DELETE_URL_PATH = 'yield/maturity_curve/delete';
export const IRRIGATION_DELETE_URL_PATH = 'irrigation/delete';
export const PER_PIXEL_YIELD_DELETE_URL_PATH = 'per_pixel_yield/delete';
export const YIELD_DELETE_URL_PATH = 'yield/delete';
export const DISEASE_DELETE_URL_PATH = 'disease-detection/delete';
// collect imagery dates path
export const ESA_IMAGERY_DATES_URL_PATH = 'esa/dates';
export const MATURITY_MAP_IMAGERY_DATES_URL_PATH = 'maturity_image/dates';
export const SOILNUTRIENT_IMAGERY_DATES_URL_PATH = 'soil-nutrient/dates';
export const SOILCARBON_IMAGERY_DATES_URL_PATH = 'soil-organic-carbon/dates';
export const MATURITYCURVE_IMAGERY_DATES_URL_PATH = 'yield/maturity_curve/dates';
export const IRRIGATION_IMAGERY_DATES_URL_PATH = 'irrigation/dates';
export const PER_PIXEL_YIELD_IMAGERY_DATES_URL_PATH = 'per_pixel_yield/dates';
export const YIELD_IMAGERY_DATES_URL_PATH = 'yield/prediction-dates';
export const DISEASE_IMAGERY_DATES_URL_PATH = 'disease-detection/dates';
// units
export const G_PER_KG = 'g/kg';
export const PERCENTAGE = '%';
export const BAUME = 'baume';
export const BRIX = 'brix';
export const SUGAR = 'sugar';
export const baume = 'baume';
export const brix = 'brix';
export const sugar = 'sugar';
export const GROWING_DEGREE_DAYS = 'Growing Degree Days';

export const PH = 'PH';
export const TA = 'TA';
// season graph colors
export const colors = ['blue', 'red', 'orange', 'green', 'pink', 'purple', 'cyan', 'magenta', 'teal', 'gray', 'yellow', 'brown', 'indigo', 'violet'];
// season indices
export const MAP = 'map';
export const HALF = 'half';
export const FULL = 'full';
export const INDEX = 'index';

export const excludeFeature = ['IS_CALIBRATED_MATURITY', 'PLANET', 'YIELD'];
export const ORGANIZATION = 'Organization';
export const FARM = 'Farm';

export const PERSONAL = 'PERSONAL';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const ACCOUNT = 'ACCOUNT';
export const PREFERENCES = 'PREFERENCES';

export const OLD = 'Old';
export const NEW = 'New';

export const SINGLE = 'Single';
export const MULTI = 'Multi-Type';

// soil nutrients vra
export const ZONE_DESCRIPTIONS = {
  2: ['Low', 'High'],
  3: ['Low', 'Medium', 'High'],
  4: ['Very Low', 'Low', 'High', 'Very High'],
  5: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
  6: ['Very Low', 'Low', 'Medium', 'High', 'Very High', 'Extreme'],
};
// Taken from: const soilNutrientComparisonGradient = `90deg, rgb(215, 48, 39) 0%, rgb(215, 48, 39) 10%, rgb(244, 109, 67) 20%, rgb(253, 174, 97) 30%, rgb(254, 224, 139) 40%, rgb(217, 239, 139) 50%, rgb(217, 239, 139) 60%, rgb(166, 217, 106) 70%, rgb(102, 189, 99) 80%, rgb(26, 152, 80) 90%, rgb(26, 152, 80) 100%`;
export const VRA_MAP_COLORS = {
  2: ['rgb(215, 48, 39)', 'rgb(26, 152, 80)'], // Red to Green
  3: ['rgb(215, 48, 39)', 'rgb(253, 174, 97)', 'rgb(26, 152, 80)'], // Red to Yellow to Green
  4: ['rgb(215, 48, 39)', 'rgb(253, 174, 97)', 'rgb(217, 239, 139)', 'rgb(26, 152, 80)'], // Red to Yellow to Light Green to Dark Green
  5: ['rgb(215, 48, 39)', 'rgb(253, 174, 97)', 'rgb(254, 224, 139)', 'rgb(217, 239, 139)', 'rgb(26, 152, 80)'], // Red to Yellow to Light Yellow to Light Green to Dark Green
  6: ['rgb(215, 48, 39)', 'rgb(244, 109, 67)', 'rgb(254, 224, 139)', 'rgb(217, 239, 139)', 'rgb(166, 217, 106)', 'rgb(26, 152, 80)'], // Red to Light Red to Light Yellow to Light Green to Medium Green to Dark Green
};
export const SOIL_FERTILISER_ZONES = 'soil_fertiliser_zones';
export const NITROGEN = 'nitrogen';
export const PHOSPHORUS = 'phosphorus';
export const POTASSIUM = 'potassium';
export const NDVI = 'NDVI';
export const GEOJSON = 'GEOJSON';
// Individual Disease Detection
export const BOTRYTIS = 'BOTRYTIS';
export const DOWNY_MILDEW = 'DOWNY_MILDEW';
export const POWDERY_MILDEW = 'POWDERY_MILDEW';
