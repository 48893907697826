import React, {useEffect, useState} from 'react';
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapPlaceholder, {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import Layout from '../../Layout';
import SelectDisease from './RightbarContent/SelectDisease';
import Sidebar from './Sidebar';
import Map from './Map';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import usePolygon from '../../../hooks/usePolygon';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '../../../components/UI/PageTitle';
import Rightbar from '../../../components/UI/Rightbar';
import RightbarButton from '../../../components/UI/Rightbar/RightbarButton';
import {IDiseaseImages, IDiseaseIndividualPolygon, IDiseaseIndividualResponse} from '@deep-planet/api-interfaces';
import {useDiseaseDetection} from '../../../hooks/useDiseaseDetection';
import {useDiseaseLabels} from '../../../hooks/useDiseaseLabels';
import {useSelector} from 'react-redux';
import {selectedOrganizationSelector} from '../../../store/selectors/organization';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {display: 'flex', flexDirection: 'row', backgroundColor: 'white'},
    title: {
      fontWeight: 'bold',
    },
  })
);

const DiseaseDetection = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const {selectedFarm, activeFarm, allFarms, loadingGetFarm, loadingGetUser, handleFarmChange} = useFarmSelection();

  const {diseaseDetectionImages, fetchDiseaseDetectionImages, loading} = useDiseaseDetection(activeFarm, startDate, endDate);

  const [imagesOnDate, setImagesOnDate] = useState<IDiseaseIndividualResponse[]>();

  const {diseaseTypeLabels, marks, mixedColors, diseaseTypes, probabilityThreshold, handleDiseaseTypeToggle, handleProbabilityThresholdChange} = useDiseaseLabels(imagesOnDate);

  const [filteredImages, setFilteredImages] = useState<IDiseaseImages[]>([]);
  const [rightbarOpen, setRightbarOpen] = useState<boolean>(false);

  const {handleOnClickPolygon, polygonClicked, selectedPolygon} = usePolygon();
  useMarkerColor();

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleFarmSelection = (farmId: string) => {
    handleFarmChange(farmId);
  };

  const handleSelectDate = (fileDate: number) => {
    const selectedFiles = diseaseDetectionImages.filter(file => file.date === fileDate);
    setImagesOnDate(selectedFiles);
  };

  // get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const toggleRightbar = () => {
    setRightbarOpen(!rightbarOpen);
  };

  useEffect(() => {
    // Filter images based on disease type and threshold
    const filterImages = (selectedFiles: IDiseaseIndividualResponse[]) => {
      // Transform the selected files into a flat list of image objects
      const updatedImages = selectedFiles?.flatMap(({polygonId, images}) =>
        // Iterate through each disease type
        diseaseTypes.flatMap(diseaseType =>
          // Filter images based on the threshold condition
          images[diseaseType]
            ?.filter(({threshold}: IDiseaseIndividualPolygon) => threshold >= probabilityThreshold)
            // Map the filtered images to the desired structure, including polygonId, url, and boundBox
            .map(({url, boundBox}: IDiseaseIndividualPolygon) => ({
              polygonId,
              url,
              boundBox,
            }))
        )
      );

      setFilteredImages(updatedImages);
    };

    // If images on date selected
    if (imagesOnDate?.length > 0) {
      setRightbarOpen(true); // Open rightbar
      filterImages(imagesOnDate);
    } else {
      setRightbarOpen(false);
    }
  }, [diseaseTypes, probabilityThreshold, imagesOnDate]);

  useEffect(() => {
    // Reset values on org change
    if (selectedOrganization) {
      setImagesOnDate([]);
      setFilteredImages([]);
    }
  }, [selectedOrganization]);

  const isLoading = loadingGetFarm || loadingGetUser;

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && activeFarm && !activeFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={activeFarm.id} />}
      {!isLoading && activeFarm && (
        <Box>
          <div id="layout">
            <ContentLeftSidebar
              sidebar={
                <Sidebar
                  selectedFarm={selectedFarm}
                  allFarms={allFarms}
                  startDate={startDate}
                  endDate={endDate}
                  loadingDates={loading}
                  files={diseaseDetectionImages}
                  selectedFiles={imagesOnDate}
                  handleFarmSelection={handleFarmSelection}
                  handleStartDateSelection={handleStartDateSelection}
                  handleEndDateSelection={handleEndDateSelection}
                  handleGetFiles={fetchDiseaseDetectionImages}
                  handleSelectDate={handleSelectDate}
                />
              }
              content={
                <>
                  <PageTitle title={t('disease.detection.title')} />
                  <Box className={classes.mapContainer}>
                    <Map
                      farm={activeFarm}
                      center={activeFarm.farmCenter}
                      images={filteredImages}
                      bbox={activeFarm.bbox}
                      polygons={activeFarm.polygons}
                      takeScreenshot={takeScreenshot}
                      handlePolygonClick={handleOnClickPolygon}
                      selectedPolygon={selectedPolygon}
                      polygonClicked={polygonClicked}
                    />
                    <Rightbar
                      open={rightbarOpen}
                      handleBack={() => {
                        setRightbarOpen(false);
                      }}
                    >
                      <SelectDisease
                        imagesOnDate={imagesOnDate}
                        diseaseTypes={diseaseTypes}
                        handleDiseaseTypeToggle={handleDiseaseTypeToggle}
                        probabilityThreshold={probabilityThreshold}
                        handleProbabilityThresholdChange={handleProbabilityThresholdChange}
                        mixedColors={mixedColors}
                        marks={marks}
                        diseaseTypeLabels={diseaseTypeLabels}
                      />
                    </Rightbar>
                  </Box>
                  {imagesOnDate?.length > 0 && <RightbarButton rightbarOpen={rightbarOpen} onClick={toggleRightbar} />}
                </>
              }
            />
          </div>
        </Box>
      )}
    </Layout>
  );
};
export default DiseaseDetection;
