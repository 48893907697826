import axios from 'axios';
import {Dispatch} from 'redux';
import i18n from '../../../i18n';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {baseApiUrl, DOWNY_MILDEW} from '../../config/const';
import {IFarm} from '../reducers/farm';
import ActionTypes from './actionTypes';
import {farmIdUpdate} from './farm';
import {IDiseaseIndividualResponse} from '@deep-planet/api-interfaces';

interface IDiseaseDetectionGetStart {
  type: ActionTypes.DISEASE_DETECTION_GET_START;
}

export const diseaseDetectionGetStart = (): IDiseaseDetectionGetStart => ({
  type: ActionTypes.DISEASE_DETECTION_GET_START,
});

interface IDiseaseDetectionGetSuccess {
  type: ActionTypes.DISEASE_DETECTION_GET_SUCCESS;
  images: any[];
  farmId: string;
  activeFarm: IFarm;
}

export const diseaseDetectionGetSuccess = (images: any[], farmId: string, activeFarm: IFarm): IDiseaseDetectionGetSuccess => ({
  type: ActionTypes.DISEASE_DETECTION_GET_SUCCESS,
  images,
  farmId,
  activeFarm,
});

interface IDiseaseDetectionGetFail {
  type: ActionTypes.DISEASE_DETECTION_GET_FAIL;
  error: unknown;
}

export const diseaseDetectionGetFail = (error): IDiseaseDetectionGetFail => ({
  type: ActionTypes.DISEASE_DETECTION_GET_FAIL,
  error,
});

interface IDiseaseDetectionImageSetSelectedDate {
  type: ActionTypes.DISEASE_DETECTION_SET_SELECTED_DATE;
  selectedDate: number;
}

export const diseaseDetectionImagesSetSelectedDate = (selectedDate: number): IDiseaseDetectionImageSetSelectedDate => ({
  type: ActionTypes.DISEASE_DETECTION_SET_SELECTED_DATE,
  selectedDate,
});

export const getDiseaseDetectionImages = (farm: IFarm, startDate: string, endDate: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(diseaseDetectionGetStart());
    const diseaseImageGetUrl = `${baseApiUrl}/disease-detection/images/${farm.id}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get<IDiseaseIndividualResponse[]>(diseaseImageGetUrl);
      dispatch(diseaseDetectionGetSuccess(data?.length ? data : [], farm.id, farm));
      dispatch(farmIdUpdate(farm.id));
      if (!data?.length) dispatch(enqueueSnackbar({message: i18n.t('error.http.response.disease.not.found', {name: farm.name}), options: {variant: 'error'}}));
    } catch (err) {
      console.log(err);
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response.disease.not.found', {name: farm.name}), options: {variant: 'error'}}));
      dispatch(diseaseDetectionGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof diseaseDetectionGetStart>
  | ReturnType<typeof diseaseDetectionGetSuccess>
  | ReturnType<typeof diseaseDetectionGetFail>
  | ReturnType<typeof farmIdUpdate>
  | ReturnType<typeof diseaseDetectionImagesSetSelectedDate>;
