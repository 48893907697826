import {useSelector} from 'react-redux';
import React from 'react';
import {GroundOverlay} from '@react-google-maps/api';
import {IDisseaseIndividualImage} from '@deep-planet/api-interfaces';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {Map, MapProps} from '../../../components/UI/Map/Map';
import PolygonWithInfo from '../../../components/UI/PolygonWithInfo/PolygonWithInfo';
import Marker from '../../../components/UI/Marker/Marker';
import {useMarker} from '../../../hooks/useMarker';
import {IFarm} from '../../../store/reducers/farm';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapInfoWindow from '../../../components/UI/PolygonWithInfo/InfoWindow';
import {markerDeleteSelector} from '../../../store/selectors';

const renderURL = (url: string) => {
  let imageUrl = null;
  const img = new Image();
  img.crossOrigin = 'anonymous';
  imageUrl = img.src = url;
  return imageUrl;
};

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 130px)',
};

interface Props extends MapProps {
  farm: IFarm;
  images: IDisseaseIndividualImage[];
  handleSugarSwitch?: (value: string) => void;
  handlePolygonHover?: (id: string) => void;
  handlePolygonClick?: (polygon: IPolygonEntity) => void;
  handleSwitch?: (value: boolean) => void;
  takeScreenshot: () => void;
}

const DiseaseDetectionMap = ({farm, center, images, bbox, polygons, polygonClicked, selectedPolygon, handlePolygonClick, takeScreenshot}: Props) => {
  const {loading: isMarkerDeleteLoading} = useSelector(markerDeleteSelector);
  const {
    isModalOpen,
    loading,
    postMarkerLoading,
    selectedMarkerFeature,
    selectedMarker,
    mouseOverMarker,
    isOpenMarkerInfoWindow,
    isOpenModalNoteUpdate,
    markerPolygon,
    selectedPin,
    handleCloseModal,
    handleOnClickMap,
    handleMarkerSubmit,
    handleSelectedPin,
    handleMarkerPostRequest,
    handleMarkerFeatureSelection,
    handleMarkerClick,
    handleMarkerMouseOver,
    handleMarkerMouseOut,
    handleNoteUpdateClose,
    handleDeleteMarker,
  } = useMarker(farm);

  const isLoading = loading || postMarkerLoading || false;
  return (
    <>
      {isMarkerDeleteLoading && <MapOverlay position="relative" />}
      {isLoading && <MapOverlay />}
      {!isLoading && !isMarkerDeleteLoading && (
        <Map
          center={center}
          mapContainerStyle={containerStyle}
          bbox={bbox}
          polygons={polygons}
          displayRows
          selectedShowMarker={selectedMarkerFeature}
          handleMarkerClick={handleMarkerClick}
          handleMarkerMouseOver={handleMarkerMouseOver}
          handleMarkerMouseOut={handleMarkerMouseOut}
          polygonClicked={polygonClicked}
          selectedPolygon={selectedPolygon}
          focus
        >
          {images && (
            <Marker
              selectedMarker={selectedMarker}
              farm={farm}
              polygon={markerPolygon}
              featureName={'MATURITYMAP'}
              isModalOpen={isModalOpen}
              isOpenModalNoteUpdate={isOpenModalNoteUpdate}
              handleAddPin={handleSelectedPin}
              handleMarkerPostRequest={handleMarkerPostRequest}
              handleMarkerFeatureSelection={handleMarkerFeatureSelection}
              handleCloseModal={handleCloseModal}
              handleMarkerSubmit={handleMarkerSubmit}
              handleNoteUpdateClose={handleNoteUpdateClose}
              handleDeleteMarker={handleDeleteMarker}
              takeScreenshot={takeScreenshot}
            />
          )}
          {images.length > 0
            ? images?.map(image => {
                // Find polygon associated with image
                const polygon = polygons?.find(polygon => polygon.id === image.polygonId);

                if (!polygon) return null;

                // Convert boundBox array to LatLngBounds
                const [xMin, yMin, xMax, yMax] = image.boundBox; // Destructure array
                const latLngBounds = new google.maps.LatLngBounds(
                  {lat: yMin, lng: xMin}, // Southwest corner
                  {lat: yMax, lng: xMax} // Northeast corner
                );

                return (
                  <>
                    <GroundOverlay
                      key={image?.url}
                      url={renderURL(image?.url)}
                      bounds={latLngBounds} // Now a LatLngBounds instance
                      opacity={0.2} // Fixed opacity to allow them to overlap
                    />
                    <PolygonWithInfo key={polygon.id} polygon={polygon} handlePolygonClick={handlePolygonClick} handleOnClickMap={handleOnClickMap} selectedPin={!!selectedPin} disableInfoBox />
                    {mouseOverMarker && isOpenMarkerInfoWindow && (
                      <MapInfoWindow key={mouseOverMarker.latitude + mouseOverMarker.longitude} polygon={polygon} disableInfoBox={true} markerNote={mouseOverMarker} />
                    )}
                  </>
                );
              })
            : polygons &&
              polygons.map(polygon => {
                if (!polygon) return null;

                return (
                  <>
                    <PolygonWithInfo key={polygon.id} polygon={polygon} handlePolygonClick={handlePolygonClick} handleOnClickMap={handleOnClickMap} selectedPin={!!selectedPin} disableInfoBox />
                    {mouseOverMarker && isOpenMarkerInfoWindow && (
                      <MapInfoWindow key={mouseOverMarker.latitude + mouseOverMarker.longitude} polygon={polygon} disableInfoBox={true} markerNote={mouseOverMarker} />
                    )}
                  </>
                );
              })}
        </Map>
      )}
    </>
  );
};

export default DiseaseDetectionMap;
