import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/diseaseDetection';
import {IFarm} from './farm';
import {IDiseaseIndividualResponse} from '@deep-planet/api-interfaces';

export const getDiseaseDetectionImagesSelector = (state: IDiseaseDetectionState) => state.getDiseaseDetectionImages;
export const diseaseDetectionImagesSelector = (state: IDiseaseDetectionState) => state.diseaseDetectionImages;
export const diseaseDetectionActiveFarmSelector = (state: IDiseaseDetectionState) => state.activeFarm;
export const diseaseDetectionSelectedDateSelector = (state: IDiseaseDetectionState) => state.selectedDate;

export interface IDiseaseDetectionState {
  getDiseaseDetectionImages: {
    error: unknown;
    loading: boolean;
  };
  diseaseDetectionImages: IDiseaseIndividualResponse[];
  activeFarm: IFarm;
  selectedDate: number;
}

const initialState: IDiseaseDetectionState = {
  getDiseaseDetectionImages: {
    error: false,
    loading: false,
  },
  diseaseDetectionImages: [],
  activeFarm: null,
  selectedDate: null,
};

const diseaseDetectionReducer = (state: IDiseaseDetectionState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.DISEASE_DETECTION_GET_START:
      return updateObject(state, {
        getDiseaseDetectionImages: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.DISEASE_DETECTION_GET_SUCCESS:
      return updateObject(state, {
        diseaseDetectionImages: action.images,
        activeFarm: action.activeFarm,
        getDiseaseDetectionImages: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.DISEASE_DETECTION_GET_FAIL:
      return updateObject(state, {
        getDiseaseDetectionImages: {
          error: action.error,
          loading: false,
        },
      });

    case ActionTypes.DISEASE_DETECTION_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedDate: action.selectedDate,
      });
    default:
      return state;
  }
};

export default diseaseDetectionReducer;
