import {useEffect, useState} from 'react';
import {DOWNY_MILDEW, POWDERY_MILDEW, BOTRYTIS} from '../config/const';
import {IDiseaseIndividualResponse} from '@deep-planet/api-interfaces';

export const useDiseaseLabels = (imagesOnDate: IDiseaseIndividualResponse[]) => {
  const [mixedColors, setMixedColors] = useState<string[]>([]);
  const [probabilityThreshold, setProbabilityThreshold] = useState<number>(-1);
  const [diseaseTypes, setDiseaseTypes] = useState<string[]>([DOWNY_MILDEW]);
  const [marks, setMarks] = useState([]);

  // Mapping of disease types to labels and colors
  const diseaseTypeLabels = {
    DOWNY_MILDEW: {label: 'Downy Mildew', color: 'yellow'},
    POWDERY_MILDEW: {label: 'Powdery Mildew', color: '#CD7F32'},
    BOTRYTIS: {label: 'Botrytis', color: 'purple'},
  };

  // Handle toggling of disease types in the selection
  const handleDiseaseTypeToggle = (type: string) => {
    setDiseaseTypes(prevTypes => (prevTypes.includes(type) ? prevTypes.filter(diseaseType => diseaseType !== type) : [...prevTypes, type]));
  };

  // Handle changes to the probability threshold
  const handleProbabilityThresholdChange = (threshold: number) => {
    setProbabilityThreshold(threshold);
  };

  // Function to calculate mixed colors based on disease type combinations
  const calculateMixedColors = (diseaseTypes: string[]) => {
    const diseaseColorCombinations = {
      // Downy first
      [`${DOWNY_MILDEW}>${POWDERY_MILDEW}`]: 'orange',
      [`${DOWNY_MILDEW}>${BOTRYTIS}`]: '#DE3163',
      [`${DOWNY_MILDEW}>${POWDERY_MILDEW}>${BOTRYTIS}`]: '#F33A6A',
      [`${DOWNY_MILDEW}>${BOTRYTIS}>${POWDERY_MILDEW}`]: '#FF5F1F',

      // Powdery first
      [`${POWDERY_MILDEW}>${DOWNY_MILDEW}`]: '#FFBF00',
      [`${POWDERY_MILDEW}>${BOTRYTIS}`]: '#FF00FF',
      [`${POWDERY_MILDEW}>${DOWNY_MILDEW}>${BOTRYTIS}`]: '#F88379',
      [`${POWDERY_MILDEW}>${BOTRYTIS}>${DOWNY_MILDEW}`]: '#FF7F50',

      // Botrytis first
      [`${BOTRYTIS}>${DOWNY_MILDEW}`]: '#F89880',
      [`${BOTRYTIS}>${POWDERY_MILDEW}`]: '#DC143C',
      [`${BOTRYTIS}>${DOWNY_MILDEW}>${POWDERY_MILDEW}`]: '#FF7518',
      [`${BOTRYTIS}>${POWDERY_MILDEW}>${DOWNY_MILDEW}`]: '#F28C28',
    };

    if (diseaseTypes.length > 1) {
      const combinations = [];

      if (diseaseTypes.length === 3) {
        const firstCombination = [...diseaseTypes];
        firstCombination.pop();
        combinations.push(diseaseColorCombinations[firstCombination.join('>')]);

        const secondCombination = [...diseaseTypes];
        secondCombination.shift();
        combinations.push(diseaseColorCombinations[secondCombination.join('>')]);
      }

      combinations.push(diseaseColorCombinations[diseaseTypes.join('>')]);
      return combinations.filter(color => !!color);
    }

    return [];
  };

  // Update mixed colors whenever disease types change
  useEffect(() => {
    setMixedColors(calculateMixedColors(diseaseTypes));
  }, [diseaseTypes]);

  // Update marks and threshold when images change
  useEffect(() => {
    // Function to process image thresholds and update marks
    const processImageThresholds = (images: IDiseaseIndividualResponse[]) => {
      const thresholds = images?.flatMap(file => Object.values(file.images).flatMap(polygons => polygons.map(polygon => polygon.threshold)));

      if (thresholds && thresholds.length > 0) {
        const sortedThresholds = thresholds.sort((a, b) => a - b);

        if (probabilityThreshold === -1) {
          setProbabilityThreshold(sortedThresholds[0]); // Set initial threshold
        }

        const uniqueThresholds = [...new Set(sortedThresholds)];
        return uniqueThresholds.map(threshold => ({value: threshold, label: null}));
      }

      return [];
    };

    if (imagesOnDate) {
      setMarks(processImageThresholds(imagesOnDate));
    }
  }, [imagesOnDate, probabilityThreshold]);

  return {
    diseaseTypeLabels,
    marks,
    mixedColors,
    diseaseTypes,
    probabilityThreshold,
    handleDiseaseTypeToggle,
    handleProbabilityThresholdChange,
  };
};
