import {useSelector} from 'react-redux';
import React, {useState} from 'react';
import {GroundOverlay} from '@react-google-maps/api';
import {cloneDeep} from 'lodash';
import {IDiseaseImages, IMaturityImages, IGetYieldPerPixelImages} from '@deep-planet/api-interfaces';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {Map, MapProps} from '../../../components/UI/Map/Map';
import PolygonWithInfo from '../../../components/UI/PolygonWithInfo/PolygonWithInfo';
import SugarMeasureSwitcher from '../../../components/UI/Switch/SugarMeasureSwitch';
import {SwitchButton} from '../../../components/UI/Switch/switch';
import OpacityPanel from '../../../components/UI/Panel/OpacityPanel';
import Marker from '../../../components/UI/Marker/Marker';
import {useMarker} from '../../../hooks/useMarker';
import {IFarm} from '../../../store/reducers/farm';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapInfoWindow from '../../../components/UI/PolygonWithInfo/InfoWindow';
import {markerDeleteSelector} from '../../../store/selectors';
import {renderURL} from './../NDVI/DownloadScreenshot';
import {PH, SUGAR, TA} from '../../../config/const';

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 130px)',
};

interface Props extends MapProps {
  farm: IFarm;
  images: IMaturityImages[] | IDiseaseImages[] | IGetYieldPerPixelImages['images'];
  metric?: string;
  scaleType?: string;
  showTonnes?: boolean;
  handleSugarSwitch?: (value: string) => void;
  handlePolygonHover?: (id: string) => void;
  handlePolygonClick?: (polygon: IPolygonEntity) => void;
  handleSwitch?: (value: boolean) => void;
  takeScreenshot: () => void;
  isCalibrated?: boolean;
  maturityType?: string;
  baumeToBrixToSugar?: (baume: number) => number;
}

const MaturityMap = ({
  farm,
  center,
  images,
  bbox,
  polygons,
  metric,
  scaleType,
  showTonnes,
  handleSugarSwitch,
  handlePolygonHover,
  handleSwitch,
  takeScreenshot,
  handlePolygonClick,
  polygonClicked,
  selectedPolygon,
  isCalibrated,
  maturityType,
  baumeToBrixToSugar,
}: Props) => {
  const farmBBox = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));
  const [opacity, setOpacity] = useState(100);
  const {loading: isMarkerDeleteLoading} = useSelector(markerDeleteSelector);
  const {
    isModalOpen,
    loading,
    postMarkerLoading,
    selectedMarkerFeature,
    selectedMarker,
    mouseOverMarker,
    isOpenMarkerInfoWindow,
    isOpenModalNoteUpdate,
    markerPolygon,
    selectedPin,
    handleCloseModal,
    handleOnClickMap,
    handleMarkerSubmit,
    handleSelectedPin,
    handleMarkerPostRequest,
    handleMarkerFeatureSelection,
    handleMarkerClick,
    handleMarkerMouseOver,
    handleMarkerMouseOut,
    handleNoteUpdateClose,
    handleDeleteMarker,
  } = useMarker(farm);

  const handleOpacityChange = (event, newValue) => {
    newValue = newValue / 100;
    setOpacity(newValue);
  };

  // disable sugar switch button if PH or TA is selected
  const phTaEnabled = maturityType && (maturityType.toUpperCase() === PH || maturityType.toUpperCase() === TA);
  const isLoading = loading || postMarkerLoading || false;
  return (
    <>
      {isMarkerDeleteLoading && <MapOverlay position="relative" />}
      {isLoading && <MapOverlay />}
      {!isLoading && !isMarkerDeleteLoading && (
        <Map
          center={center}
          mapContainerStyle={containerStyle}
          bbox={bbox}
          polygons={polygons}
          displayRows
          selectedShowMarker={selectedMarkerFeature}
          handleMarkerClick={handleMarkerClick}
          handleMarkerMouseOver={handleMarkerMouseOver}
          handleMarkerMouseOut={handleMarkerMouseOut}
          polygonClicked={polygonClicked}
          selectedPolygon={selectedPolygon}
          focus
        >
          {images && (
            <>
              <OpacityPanel opacity={opacity} handleOpacityChange={handleOpacityChange} />
              <Marker
                selectedMarker={selectedMarker}
                farm={farm}
                polygon={markerPolygon}
                featureName={'MATURITYMAP'}
                isModalOpen={isModalOpen}
                isOpenModalNoteUpdate={isOpenModalNoteUpdate}
                handleAddPin={handleSelectedPin}
                handleMarkerPostRequest={handleMarkerPostRequest}
                handleMarkerFeatureSelection={handleMarkerFeatureSelection}
                handleCloseModal={handleCloseModal}
                handleMarkerSubmit={handleMarkerSubmit}
                handleNoteUpdateClose={handleNoteUpdateClose}
                handleDeleteMarker={handleDeleteMarker}
                takeScreenshot={takeScreenshot}
              />
            </>
          )}

          {polygons?.map(polygon => {
            let blockImagery =
              images && maturityType
                ? (images as IMaturityImages[]).find(i => i.polygonId === polygon.id && i.type.toUpperCase() === maturityType.toUpperCase())
                : images?.find(({polygonId}) => polygonId === polygon.id);
            // when PH or TA selected but images are not available, just show the bound box
            if (!blockImagery && images) {
              // clone the object to modify without affecting the source entry in the Array
              blockImagery = cloneDeep(images?.find(({polygonId}) => polygonId === polygon.id));
              if (blockImagery?.url) blockImagery.url = null;
            }
            //@ts-ignore
            const avgBaume = isCalibrated ? baumeToBrixToSugar(blockImagery?.avgBaume).toString() : null;
            if (blockImagery) {
              const bounds = blockImagery.boundBox
                ? new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(blockImagery.boundBox[1], blockImagery.boundBox[0]),
                    new window.google.maps.LatLng(blockImagery.boundBox[3], blockImagery.boundBox[2])
                  )
                : farmBBox;
              return (
                <>
                  <GroundOverlay key={blockImagery.url} url={renderURL(blockImagery.url)} bounds={bounds} opacity={opacity} />
                  <PolygonWithInfo
                    key={polygon.id}
                    polygon={polygon}
                    avgBaume={avgBaume}
                    metric={metric}
                    //@ts-ignore
                    diseasePredictions={blockImagery}
                    handlePolygonHover={handlePolygonHover}
                    handlePolygonClick={handlePolygonClick}
                    handleOnClickMap={handleOnClickMap}
                    showVariety
                    selectedPin={!!selectedPin}
                  />
                  {mouseOverMarker && isOpenMarkerInfoWindow && (
                    <MapInfoWindow key={mouseOverMarker.latitude + mouseOverMarker.longitude} polygon={polygon} disableInfoBox={true} markerNote={mouseOverMarker} />
                  )}
                </>
              );
            } else {
              return null;
            }
          })}
          {/** Disable the switch button when maturity type is PH or TA */}
          {!!scaleType &&
            isCalibrated &&
            !phTaEnabled &&
            (scaleType === SUGAR ? (
              <SugarMeasureSwitcher metric={metric} handleSwitch={handleSugarSwitch} />
            ) : (
              <SwitchButton isMetric={showTonnes} handleSwitch={() => handleSwitch(showTonnes)} option1={'KG'} option2={'Tonne'} boxNumber={1} left="190px" marginTop="10px" />
            ))}
        </Map>
      )}
    </>
  );
};

export default MaturityMap;
