import {useCallback, useState} from 'react';
import axios from 'axios';
import {baseApiUrl} from '../config/const';
import {IFarm} from '../store/reducers/farm';
import {IOrganizationEntity, ISoilMoistureFile, ISoilMoisturePlotData} from '@deep-planet/api-interfaces';
import {getOrganizationIdForFarm} from '../containers/pages/Notes/getUserFarms';

const validateDateString = (date: Date) => date.toISOString().slice(0, 10);

export const useSoilMoistureInterpolation = (organizations: IOrganizationEntity[]) => {
  const [plotData, setPlotData] = useState<ISoilMoisturePlotData>(null);
  const [loadingGraph, setLoadingGraph] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loadingDates, setLoadingDates] = useState<boolean>(false);
  const [files, setFiles] = useState<ISoilMoistureFile[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>(null);
  const [renderingGraph, setRenderingGraph] = useState<boolean>(false);

  const fetchDataFromUrl = async (url: string) => {
    try {
      setRenderingGraph(false);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
      const rawText = await response.text(); // Fetch response as plain text
      // Replace NaN with null in the raw text
      const validatedText = rawText.replace(/\bNaN\b/g, 'null');
      // Parse the sanitized text as JSON
      const processedJson = JSON.parse(validatedText);
      setRenderingGraph(true);
      setTimeout(() => {
        setPlotData(processedJson); // Update plotData
      }, 100); // Set timeout to ensure rendering plot state updates first and is not halted by setData state
    } catch (error) {
      console.error('Error fetching graph plotData:', error);
      showError();
    }
  };

  const fetchSoilMoistureFiles = async (organizationId: string, farmId: string, fromDate: string, toDate: string) => {
    try {
      const updatedToDate = validateDateString(new Date(new Date(toDate).getTime() + 14 * 24 * 60 * 60 * 1000)); // Add 14 days to fetch predicted values

      const url = `${baseApiUrl}/soil-interpolation/file?organizationId=${organizationId}&farmId=${farmId}&fromDate=${fromDate}&toDate=${updatedToDate}`;
      const response = await axios.get(url);
      const data = response.data;
      setFiles(data); // Set files to fetched soil files data
      stopLoadingDates();
    } catch (error) {
      console.error('Error fetching SMI files:', error);
      showError();
    }
  };

  const handleSelectedDateChange = async (selectedFile: ISoilMoistureFile) => {
    startLoading();
    setPlotData(null);
    setError(false);
    setSelectedFile(selectedFile.url);
    const url = selectedFile.url;
    if (url) {
      await fetchDataFromUrl(url); // Fetch plot data fron file url
    } else {
      showError();
    }
  };

  const handleSubmit = (selectedOrganization: IOrganizationEntity, selectedFarm: IFarm, startDate: Date, endDate: Date) => {
    const organizationIdForFarm = getOrganizationIdForFarm(organizations, selectedFarm.id);
    if (selectedOrganization && selectedFarm && startDate && endDate && organizations.find((organization: IOrganizationEntity) => organization.id === organizationIdForFarm)) {
      startLoadingDates();
      const fromDate = validateDateString(startDate);
      const toDate = validateDateString(endDate);
      fetchSoilMoistureFiles(organizationIdForFarm, selectedFarm.farmid, fromDate, toDate); // Begin fetching soil moisture files
    } else {
      showError();
    }
  };

  const startLoading = () => {
    setLoadingGraph(true);
    setError(false);
    setPlotData(null);
  };

  const stopLoading = () => {
    setLoadingGraph(false);
    setError(false);
  };

  const startLoadingDates = () => {
    setLoadingDates(true);
    setFiles([]);
  };

  const stopLoadingDates = () => {
    setLoadingDates(false);
    setError(false);
  };

  const showError = () => {
    setLoadingGraph(false);
    setLoadingDates(false);
    setError(true);
  };

  const resetFiles = useCallback(() => {
    setFiles([]);
    setSelectedFile(null);
    setPlotData(null);
    setError(false);
  }, []);

  const handlePlotInitialised = () => {
    stopLoading();
  };

  return {plotData, files, selectedFile, loadingDates, loadingGraph, renderingGraph, error, setLoadingDates, handlePlotInitialised, handleSelectedDateChange, handleSubmit, resetFiles};
};
