import React, {useState, useEffect} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {isMobile} from 'react-device-detect';

interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    width: (props: StyleProps) => (props.open ? '350px' : '0px'),
    height: 'calc(100vh - 130px)',
    transition: 'width 0.2s ease',
    overflow: 'hidden',
    padding: (props: StyleProps) => (props.open ? 10 : 0),
  },
  mobile: {
    position: 'absolute',
    height: 'calc(100vh - 130px)',
    width: '100%',
    minWidth: 'calc(100vw + 1px)',
    transition: 'width 0.2s ease',
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 3,
  },
  mobileHidden: {
    opacity: 0,
  },
  desktop: {
    width: '350px',
    position: 'relative',
    backgroundColor: 'transparent',
  },
  backButton: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
}));

interface RightbarProps {
  open: boolean;
  handleBack: () => void;
  children: React.ReactNode;
}

const Rightbar = ({open, handleBack, children}: RightbarProps) => {
  const [showContent, setShowContent] = useState(false);
  const classes = useStyles({
    open,
  });

  // Set slight delay before showing content
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setShowContent(true);
      }, 150);
      return () => clearTimeout(timer);
    } else {
      setShowContent(false);
    }
  }, [open]);

  return <Box className={`${classes.container} ${classes.desktop}`}>{showContent && children}</Box>;
};

export default Rightbar;
