import React from 'react';
import {Button, Box, ListItem, ButtonGroup, CircularProgress, Typography, makeStyles, Theme, createStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import FarmPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/FarmPicker';
import DatePeriodPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/DatePeriodPicker';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';
import {handleDateFormat} from '../../../helpers/dateHelpers';
import {IOrganizationEntity, ISoilMoistureFile} from '@deep-planet/api-interfaces';
import {getOrganizationIdForFarm} from '../Notes/getUserFarms';

const getDateFromFileName = (fileName: string) => {
  const fileNameSplit = fileName.split('_');
  const date = fileNameSplit[fileNameSplit.length - 1].split('.')[0]; // Remove file extension (json)
  return date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 64px)',
    },
    sideList: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    selectSection: {
      padding: '1rem',
    },
    updateButton: {
      margin: '1rem 0',
      width: '100%',
    },
    buttonList: {
      overflow: 'auto',
    },
    sideListItemText: {
      fontSize: '0.8125rem',
      marginLeft: 5.5,
    },
  })
);

interface SidebarProps {
  selectedOrganization: IOrganizationEntity;
  organizations: IOrganizationEntity[];
  selectedFarm: IFarm;
  allFarms: IFarm[];
  startDate: Date;
  endDate: Date;
  files: ISoilMoistureFile[];
  selectedFile: string;
  loadingDates: boolean;
  handleChange: (farmId: string) => void;
  handleSubmit: (selectedOrganization: IOrganizationEntity, selectedFarm: IFarm, startDate: Date, endDate: Date) => void;
  handleStartDateSelection: (date: Date) => void;
  handleEndDateSelection: (date: Date) => void;
  handleSelectedDateChange: (selectedFile: ISoilMoistureFile) => void;
}

const Sidebar = ({
  selectedOrganization,
  organizations,
  selectedFarm,
  allFarms,
  startDate,
  endDate,
  files,
  selectedFile,
  loadingDates,
  handleChange,
  handleSubmit,
  handleStartDateSelection,
  handleEndDateSelection,
  handleSelectedDateChange,
}: SidebarProps) => {
  const {t} = useTranslation();
  const classes = useStyles();

  files?.sort((a, b) => {
    const aDate = new Date(getDateFromFileName(a.url));
    const bDate = new Date(getDateFromFileName(b.url));
    return bDate.getTime() - aDate.getTime();
  });

  return (
    <Box className={classes.container}>
      <Box id={'cucc'} className={classes.sideList}>
        <Box className={classes.selectSection}>
          <Box width="100%">
            <FarmPicker activeFarm={selectedFarm?.farmid} allFarms={allFarms} handleChange={handleChange} />
          </Box>
          <DatePeriodPicker
            disableToolbar={false}
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={handleStartDateSelection}
            handleEndDateChange={handleEndDateSelection}
            disableFuture={true}
          />
          <Button
            className={classes.updateButton}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit(selectedOrganization, selectedFarm, startDate, endDate);
            }}
          >
            {loadingDates ? <CircularProgress style={{color: 'white'}} size={23.5} /> : t('ndvi.sidebar.update')}
          </Button>
        </Box>
        {!!files && (
          <Box className={classes.buttonList}>
            {files?.map(filePath => {
              const date = getDateFromFileName(filePath.url);

              if (filePath.url.includes('predicted') && new Date(date).getTime() < Date.now()) {
                return null;
              }

              return (
                <ListItem key={filePath.url} button style={{backgroundColor: filePath.url === selectedFile ? '#DBDBDB' : 'unset'}}>
                  <ButtonGroup variant="text" size="small" aria-label="small outlined button group">
                    <Button
                      variant="contained"
                      color={filePath.url === selectedFile ? 'primary' : 'default'}
                      onClick={() => {
                        handleSelectedDateChange(filePath);
                      }}
                    >
                      {handleDateFormat(date)}
                      {filePath.url.includes('predicted') && (
                        <Typography className={classes.sideListItemText} style={{color: filePath.url === selectedFile ? 'white' : 'grey'}}>
                          {t('ndvi.sidebar.predicted')}
                        </Typography>
                      )}
                    </Button>
                  </ButtonGroup>
                </ListItem>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
