import {IFarmEntity, dates} from '@deep-planet/api-interfaces';
import {Box, Button, List, ListItem, ListItemText} from '@material-ui/core';
import axios from 'axios';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import Spinner from '../../../../../../components/UI/Spinner';
import {
  DISEASE_DELETE_URL_PATH,
  ESA,
  ESA_DELETE_URL_PATH,
  IRRIGATION,
  IRRIGATION_DELETE_URL_PATH,
  MATURITYCURVE,
  MATURITYCURVE_DELETE_URL_PATH,
  MATURITYMAP,
  MATURITY_MAP_DELETE_URL_PATH,
  PERPIXELYIELD,
  PER_PIXEL_YIELD_DELETE_URL_PATH,
  PLANET,
  SOILNUTRIENT,
  SOILNUTRIENT_DELETE_URL_PATH,
  SOILORGANICCARBON,
  YIELD,
  YIELD_DELETE_URL_PATH,
  baseApiUrl,
  DISEASE,
} from '../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../store/actions';
import theme from '../../../../../../utilities/theme';
import DatePeriodPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/DatePeriodPicker';
import {getDateString, getStringDate} from 'apps/web-portal-ui/src/app/helpers/dateHelpers';
import {ConfirmationDialog} from 'apps/web-portal-ui/src/app/components/UI/Dialog/ConfirmationDialog';
import _ from 'lodash';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface Props {
  farm: IFarmEntity;
  url: string;
  feature: string;
}

export const MaintainImagery = ({farm, url, feature}: Props) => {
  const [selectedPredictionDate, setSelectedPredictionDate] = useState<Date>(new Date());
  const [fromDate, setFromDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [toDate, setToDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [imageryDates, setImageryDates] = useState<Date[]>();
  const [imageryData, setImageryData] = useState<dates[]>();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [deleteURL, setDeleteURL] = useState<string>(null);

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleDelete = async () => {
    setIsOpenDialog(true);
    switch (feature) {
      case ESA:
        setDeleteURL(ESA_DELETE_URL_PATH);
        break;
      case MATURITYMAP:
        setDeleteURL(MATURITY_MAP_DELETE_URL_PATH);
        break;
      case YIELD:
        setDeleteURL(YIELD_DELETE_URL_PATH);
        break;
      case SOILNUTRIENT:
        setDeleteURL(SOILNUTRIENT_DELETE_URL_PATH);
        break;
      case IRRIGATION:
        setDeleteURL(IRRIGATION_DELETE_URL_PATH);
        break;
      case PERPIXELYIELD:
        setDeleteURL(PER_PIXEL_YIELD_DELETE_URL_PATH);
        break;
      case MATURITYCURVE:
        setDeleteURL(MATURITYCURVE_DELETE_URL_PATH);
        break;
      case DISEASE:
        setDeleteURL(DISEASE_DELETE_URL_PATH);
        break;
      case PLANET:
        break;
      case SOILORGANICCARBON:
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const {data} = await axios.get<dates[]>(`${baseApiUrl}/${url}?farmId=${farm.id}&fromDate=${fromDate}&toDate=${toDate}`);
      // get unique dates and sort by descending order
      const predictionDates = [...new Set(data.map(d => d.date).sort((a, b) => (b > a ? 1 : -1)))];
      setImageryData(data);
      setImageryDates(predictionDates);
    } catch (e) {
      setImageryDates(null);
      const {response, message} = e?.response?.data;
      dispatch(enqueueSnackbar({message: response?.message || message, options: {variant: 'error'}}));
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setIsOpenDialog(false);
    // setDeleteFarmId(null);
  };
  const handleDeleteConfirmation = async () => {
    try {
      setLoading(true);
      const payload = {ids: imageryData.filter(i => i.date === selectedPredictionDate).map(i => i.id)};
      const {data} = await axios.post<any>(`${baseApiUrl}/${deleteURL}`, payload);
      // update state
      setImageryDates(imageryDates.filter(i => !(i === selectedPredictionDate)));
      setImageryData(imageryData.filter(i => !(i.date === selectedPredictionDate)));
      // message
      dispatch(enqueueSnackbar({message: `imagery has been deleted for date ${getStringDate(selectedPredictionDate)}`, options: {variant: 'success'}}));
    } catch (e) {
      const {response} = e?.response?.data;
      dispatch(enqueueSnackbar({message: response?.message || t('error.http.response'), options: {variant: 'error'}}));
      console.log(e);
    } finally {
      setLoading(false);
    }
    setIsOpenDialog(false);
  };

  return (
    <Box width="100%" padding="0.5rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <FormWrapper width="100%">
        <form>
          <Box width="100%">
            <DatePeriodPicker disableToolbar={false} startDate={fromDate} endDate={toDate} handleStartDateChange={setFromDate} handleEndDateChange={setToDate} disableFuture={true} />
          </Box>
          <Box marginTop="2rem" width="100%" display="display" flexDirection="column">
            {imageryDates && (
              <List style={{overflow: 'auto', maxHeight: 300, position: 'relative'}}>
                <ListItem />
                {imageryDates.map((date, idx) => {
                  return (
                    <ListItem key={`${date} + ${idx}`} style={{backgroundColor: selectedPredictionDate === date ? '#DBDBDB' : 'unset'}} onClick={() => setSelectedPredictionDate(date)} button>
                      <ListItemText primary={getDateString(date)} />
                    </ListItem>
                  );
                })}
              </List>
            )}
            <Box mt={4} mb={4} display="flex" flexDirection="column" justifyContent="flex-end" width="100%">
              <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit} style={{height: 'auto'}} fullWidth>
                Get Imagery
                {loading && <Spinner size={15} color="primary" />}
              </Button>
              {imageryDates && selectedPredictionDate && (
                <Button variant="contained" color="primary" disabled={loading} onClick={handleDelete} style={{height: 'auto', marginTop: '0.5rem'}} fullWidth>
                  Delete
                  {loading && <Spinner size={15} color="primary" />}
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </FormWrapper>
      <ConfirmationDialog
        open={isOpenDialog}
        handleClose={handleDialogClose}
        handleSubmit={handleDeleteConfirmation}
        title={`You are deleting the imagery for date ${getDateString(selectedPredictionDate)}`}
        submitText={'YES'}
      />
    </Box>
  );
};
