import React from 'react';
import styled from 'styled-components';
import {Button, ButtonGroup, List, ListItem, Tooltip, Typography, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import FarmPicker from '../../../components/UI/Pickers/FarmPicker';
import {handleDateFormat} from '../../../helpers/dateHelpers';
import DatePeriodPicker from '../../../components/UI/Pickers/DatePeriodPicker';
import {IFarm} from '../../../store/reducers/farm';
import {IImageWithBox} from '../../../store/reducers/ndvi';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
`;

const SideList = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

interface Props {
  activeFarm: IFarm;
  allFarms: IFarm[];
  images: IImageWithBox[];
  startDate: Date;
  endDate: Date;
  selectedDate: number;
  type: string;
  handleChange: (farmId: string) => void;
  handlePostRequest: () => void;
  handleUpdateDateIndex: (idx: number, type: string) => void;
  handleStartDateSelection: (date: Date) => void;
  handleEndDateSelection: (date: Date) => void;
}

const Sidebar = ({
  activeFarm,
  allFarms,
  handleChange,
  images,
  type,
  handlePostRequest,
  handleUpdateDateIndex,
  handleStartDateSelection,
  handleEndDateSelection,
  startDate,
  endDate,
  selectedDate,
}: Props) => {
  const {t} = useTranslation();
  return (
    <Container>
      <SideList id={'cucc'}>
        <Box width="100%">
          <FarmPicker activeFarm={activeFarm?.farmid} allFarms={allFarms} handleChange={handleChange} />
        </Box>
        <DatePeriodPicker
          disableToolbar={false}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateSelection}
          handleEndDateChange={handleEndDateSelection}
          disableFuture={true}
        />
        <Button variant="contained" color="primary" onClick={handlePostRequest} style={{margin: '1rem 0'}}>
          {t('ndvi.sidebar.update')}
        </Button>
      </SideList>
      {!!images?.length && (
        <List style={{overflow: 'auto'}}>
          {images.map(({images, date}) => (
            <ListItem
              button
              key={date}
              style={{backgroundColor: images[0]?.imageUrl?.includes('PleiadesNDVI') || images[0]?.imageUrl?.includes('SkySatNDVI') ? '#253655' : selectedDate === date ? '#DBDBDB' : 'unset'}}
            >
              <ButtonGroup variant="contained" size="small" aria-label="small outlined button group">
                <Button
                  style={{whiteSpace: 'nowrap'}}
                  color={date === selectedDate && (type === 'current' || type === 'predicted') ? 'primary' : 'default'}
                  onClick={() => handleUpdateDateIndex(date, images.some(i => i.imagePredictedUrl) ? 'predicted' : 'current')}
                >
                  {handleDateFormat(date) + (images.some(i => i.imagePredictedUrl) ? ` - ${t('ndvi.sidebar.predicted')}` : '')}
                </Button>
                {images.some(i => i.imagePreviousUrl) ? (
                  <Tooltip title={t('ndvi.sidebar.compare.previous.tooltip')} arrow>
                    <Button style={{whiteSpace: 'nowrap'}} color={date === selectedDate && type === 'previous' ? 'primary' : 'default'} onClick={() => handleUpdateDateIndex(date, 'previous')}>
                      {t('ndvi.sidebar.previous')}
                    </Button>
                  </Tooltip>
                ) : null}
                {images.some(i => i.imageAverageUrl) ? (
                  <Tooltip title={t('ndvi.sidebar.compare.average.tooltip')} arrow>
                    <Button style={{whiteSpace: 'nowrap'}} color={date === selectedDate && type === 'average' ? 'primary' : 'default'} onClick={() => handleUpdateDateIndex(date, 'average')}>
                      {t('ndvi.sidebar.average')}
                    </Button>
                  </Tooltip>
                ) : null}
              </ButtonGroup>
            </ListItem>
          ))}
        </List>
      )}

      {!images?.length && (
        <Box pr={2} pl={2}>
          <Typography align="center">{t('ndvi.sidebar.no.images')}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default Sidebar;
