import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IFarm} from '../store/reducers/farm';
import {diseaseDetectionImagesSelector} from '../store/selectors/diseaseDetection';
import {getDiseaseImagesSelector} from '../store/selectors/disease';
import {getStringDate} from '../helpers/dateHelpers';
import {getDiseaseDetectionImages} from '../store/actions/diseaseDetection';
import {selectedOrganizationSelector} from '../store/selectors';
import {usePrevious} from './usePrevious';

export function useDiseaseDetection(activeFarm: IFarm, startDate: Date, endDate: Date, lazy = false) {
  const dispatch = useDispatch();

  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const diseaseDetectionImages = useSelector(diseaseDetectionImagesSelector);
  const {loading, error} = useSelector(getDiseaseImagesSelector);

  const prevFarm = usePrevious(activeFarm);

  // Get the images for the first time when screen is initialized or selected organization changes
  useEffect(() => {
    if (activeFarm && !loading && !diseaseDetectionImages) {
      dispatch(getDiseaseDetectionImages(activeFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
    }
  }, [activeFarm, diseaseDetectionImages, dispatch, endDate, loading, selectedOrganization, startDate]);

  // In case of selected organization is changed
  useEffect(() => {
    if (activeFarm && prevFarm && activeFarm.id !== prevFarm.id && !loading) {
      dispatch(getDiseaseDetectionImages(activeFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization.id));
    }
  }, [activeFarm, dispatch, endDate, loading, prevFarm, selectedOrganization, startDate]);

  const fetchDiseaseDetectionImages = (selectedFarm: IFarm) => {
    dispatch(getDiseaseDetectionImages(selectedFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
  };

  return {diseaseDetectionImages, fetchDiseaseDetectionImages, loading, error};
}
