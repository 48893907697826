import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, Box, Divider, Checkbox, FormControlLabel, Slider} from '@material-ui/core';
import useSelectDiseaseStyles from './useSelectDiseaseStyles';
import {BOTRYTIS} from '../../../../config/const';
import {IDiseaseIndividualResponse} from '@deep-planet/api-interfaces';

export type DynamicScaleType = [number, number, number, number, number, number, number, number];

interface Props {
  imagesOnDate: IDiseaseIndividualResponse[];
  diseaseTypes: string[];
  handleDiseaseTypeToggle: (type: string) => void;
  probabilityThreshold: number;
  handleProbabilityThresholdChange: (value: any) => void;
  mixedColors: string[];
  marks: {value: number; label: null}[];
  diseaseTypeLabels: {[key: string]: {label: string; color: string}};
}

const SelectDisease = ({imagesOnDate, diseaseTypes, handleDiseaseTypeToggle, probabilityThreshold, handleProbabilityThresholdChange, mixedColors, marks, diseaseTypeLabels}: Props) => {
  const {t} = useTranslation();
  const classes = useSelectDiseaseStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="h6">
        {t('disease.detection.rightbar.title')}
      </Typography>
      <Box className={classes.probabilityContainer}>
        <Typography className={classes.selectLabel} variant="body1">
          {t('disease.detection.threshold.title')}
        </Typography>
        <Box className={classes.selectContainer}>
          <Typography className={classes.thresholdLabel} variant="body1">
            {t('disease.detection.threshold.label')}
          </Typography>
          <Box className={classes.separator} />
          <Box className={classes.sliderContainer}>
            <Slider
              value={probabilityThreshold}
              onChange={(event, value) => handleProbabilityThresholdChange(value)}
              aria-labelledby="discrete-slider"
              step={null} // Only allow marked values
              marks={marks}
              min={0}
              max={100}
              valueLabelDisplay="on"
              track={false}
              valueLabelFormat={x => x + '%'}
            />
          </Box>
        </Box>
      </Box>
      <Typography className={classes.selectLabel} variant="body1">
        {t('disease.detection.type.title')}
      </Typography>
      <Box className={classes.buttonListContainer}>
        {Object.entries(diseaseTypeLabels).map(([key, labelObj]) => {
          if (!imagesOnDate.some(file => file.images[key].length > 1)) return null;
          return (
            <>
              <Box key={key} className={classes.buttonContainer}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={<Checkbox className={classes.radioButtton} checked={diseaseTypes?.includes(key)} size="small" color="primary" onClick={() => handleDiseaseTypeToggle(key)} />}
                  label={
                    <Typography className={classes.formControlLabelText} style={{color: diseaseTypes?.includes(key) ? 'black' : 'gray'}}>
                      {labelObj.label}
                    </Typography>
                  }
                />
                <Box className={classes.separator} />
                <Box className={classes.diseaseColorBox} style={{backgroundColor: labelObj.color}} />
              </Box>
              {key === BOTRYTIS ? null : <Divider />}
            </>
          );
        })}
      </Box>
      <Box className={`${classes.mixtureContainer} ${diseaseTypes.length > 1 ? 'visible' : ''}`}>
        <Typography className={classes.multipleText}>{t('disease.detection.mixture.type.label')}</Typography>
        <Box className={classes.separator} />
        <Box className={classes.colorBoxRow}>
          {mixedColors.map((color, index) => (
            <Box key={index} className={`${classes.smallDiseaseColorBox}`} style={{backgroundColor: color}} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(SelectDisease);
